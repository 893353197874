<template>
  <div>
    <div class="text-center display-1 font-weight-normal">
      Selecione o tipo de mapa a ser plotado
    </div>
    <v-row class="mx-auto">
      <v-col class="pb-0 d-flex justify-center">
        <v-radio-group
          v-model="_mapType"
          row
        >
          <v-radio
            label="Visualizar Ilhas"
            value="ilha"
          />
          <v-radio
            label="Trafos MT-MT"
            value="mtmt"
          />
          <v-radio
            label="Hierarquia de tensão"
            value="grafico1"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="_mapType === 'ilha'"
    >
      <v-col class="pb-0 d-flex justify-center">
        <v-text-field
          v-model="_numberOfIslands"
          label="N° de Ilhas"
          type="number"
          :min="1"
          :step="1"
          outlined
        />
      </v-col>
    </v-row>
    <div class="text-center display-1 font-weight-normal mt-10">
      Informe o CTMT que será plotado no mapa
    </div>
    <v-row class="mx-auto">
      <v-col class="pb-0">
        <v-text-field
          v-model="searchCtmt"
          append-icon="mdi-magnify"
          class="ml-auto mb-4 break-search"
          label="Procurar"
          hide-details
          single-line
        />
        <v-data-table
          v-model="_selectedCtmt"
          :headers="[
            { text: 'CTMT', value: 'cod_id' },
            { text: 'NOME', value: 'nome' }
          ]"
          :items="ctmts"
          :search="searchCtmt"
          :sort-by="['nome']"
          item-key="cod_id"
          single-select
          show-select
          dense
          class="elevation-1"
          :items-per-page="5"
          no-data-text="Nenhum CTMT encontrado"
        />
      </v-col>
    </v-row>
    <div class="text-center display-1 font-weight-normal mt-10">
      Entidades a serem plotadas
    </div>
    <v-row class="mx-auto">
      <v-col class="pb-0">
        <v-text-field
          v-model="searchEntity"
          append-icon="mdi-magnify"
          class="ml-auto mb-4 break-search"
          label="Procurar"
          hide-details
          single-line
        />
        <v-data-table
          v-model="_selectedEntities"
          :headers="[
            { text: 'Elemento', value: 'element' },
            { text: 'Entidade na BDGD', value: 'bdgd_entity' }
          ]"
          :items="entities"
          :search="searchEntity"
          :sort-by="['bdgd_entity']"
          item-key="element"
          show-select
          dense
          class="elevation-1"
          hide-default-footer
          disable-pagination
          no-data-text="Nenhuma entidade da BDGD encontrada"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    ctmts: {
      type: Array,
      default: () => []
    },
    mapType: {
      type: String,
      default: 'ilha'
    },
    numberOfIslands: {
      type: Number,
      default: 10
    },
    selectedCtmt: {
      type: Array,
      default: []
    },
    entities: {
      type: Array,
      default: []
    },
    selectedEntities: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    searchCtmt: null,
    searchEntity: null
  }),
  computed: {
    _mapType: {
      get() {
        return this.mapType;
      },
      set(mapType) {
        this.$emit('update:mapType', mapType);
      }
    },
    _numberOfIslands: {
      get() {
        return this.numberOfIslands;
      },
      set(numberOfIslands) {
        this.$emit('update:numberOfIslands', Number(numberOfIslands));
      }
    },
    _selectedCtmt: {
      get() {
        return this.selectedCtmt;
      },
      set(selectedCtmt) {
        this.$emit('update:selectedCtmt', selectedCtmt);
      }
    },
    _selectedEntities: {
      get() {
        return this.selectedEntities;
      },
      set(selectedEntities) {
        this.$emit('update:selectedEntities', selectedEntities);
      }
    },
    isValidParameters() {
      return (
        this._numberOfIslands > 0 &&
        this._selectedCtmt &&
        this._selectedCtmt.length === 1 &&
        this._selectedEntities &&
        this._selectedEntities.length > 0
      );
    }
  },
  watch: {
    isValidParameters(isValid) {
      this.$emit('stepReleased', isValid);
    }
  }
};
</script>
