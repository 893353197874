import APIService from './APIService';

export default {
  getCtmtsByBdgdVersaoId(bdgdVersionId) {
    return APIService.apiCall().get(
      `/mapas-sequenciamentos-eletricos/get-ctmts-by-bdgd-versao-id/${bdgdVersionId}`
    );
  },
  getEntities(bdgdVersionId) {
    return APIService.apiCall().get(
      `/mapas-sequenciamentos-eletricos/get-entities/${bdgdVersionId}`
    );
  },
  getAll() {
    return APIService.apiCall().get('/mapas-sequenciamentos-eletricos/get-all');
  },
  executar(id) {
    return APIService.apiCall().get(`/mapas-sequenciamentos-eletricos/executar/${id}`);
  },
  deletar(id) {
    return APIService.apiCall().get(`/mapas-sequenciamentos-eletricos/excluir/${id}`);
  },
  save(postData) {
    return APIService.apiCall().post(
      '/mapas-sequenciamentos-eletricos/save',
      JSON.stringify(postData)
    );
  },
  baixarArquivoMapa(id, config = {}) {
    return APIService.apiCall().get(
      `/mapas-sequenciamentos-eletricos/baixar-arquivo-mapa/${id}`,
      { responseType: 'blob', ...config }
    );
  }
};