<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Novo Mapa"
      subtitle="Selecione a versão da BDGD e CTMT"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <mapas-sequenciamento-eletrico-wizard-tabs
        ref="mapaSequenciamentoParametros"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import MapasSequenciamentoEletricoService from '@/services/MapasSequenciamentoEletricoService';
import MapasSequenciamentoEletricoWizardTabs from '@/components/perdas-tecnicas/sequenciamento-eletrico/mapas/MapasSequenciamentoEletricoWizardTabs';
import MaterialWizard from '@/components/base/MaterialWizard';
import wizardMixins from '@/mixins/wizardMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins],
  components: {
    MapasSequenciamentoEletricoWizardTabs,
    MaterialWizard
  },
  data: () => ({
    tabs: ['Mês dos Dados', 'Parâmetros', 'Confirmação'],
    loading: false
  }),
  methods: {
    save() {
      const data = this.$refs.mapaSequenciamentoParametros.exportData();
      this.loading = true;
      MapasSequenciamentoEletricoService.save(data)
        .then(() => {
          this.$toast.success(
            'Mapa de sequenciamento elétrico salvo com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.$router.replace({
            name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO_GERAR_MAPAS']
          });
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao salvar o mapa de sequenciamento elétrico.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
