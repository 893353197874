<template>
  <v-container fluid>
    <v-tab-item
      class="pb-0"
      eager
    >
      <first-tab-content
        :itemsTable="itemsTableFirstTab"
        :selectedItem.sync="selectedItemsFirstTab"
        :titleItem.sync="titleItem"
        :loading.sync="loadingFirstTab"
        @stepReleased="$emit('step-observer', { step: 0, complete: $event })"
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <second-tab-content
        :ctmts="ctmts"
        :mapType.sync="mapType"
        :numberOfIslands.sync="numberOfIslands"
        :entities.sync="entities"
        :selectedCtmt.sync="selectedCtmt"
        :selectedEntities.sync="selectedEntities"
        @stepReleased="
          $emit('step-observer', { step: 1, complete: $event }),
            $emit('step-observer', { step: 2, complete: $event })
        "
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <third-tab-content />
    </v-tab-item>
  </v-container>
</template>

<script>
import MapasSequenciamentoEletricoService from '@/services/MapasSequenciamentoEletricoService';
import ExportacoesBdgdsService from '@/services/ExportacoesBdgdsService';
import FirstTabContent from '@/components/perdas-tecnicas/sequenciamento-eletrico/mapas/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/perdas-tecnicas/sequenciamento-eletrico/mapas/tabs/SecondTabContent.vue';
import ThirdTabContent from '@/components/perdas-tecnicas/sequenciamento-eletrico/mapas/tabs/ThirdTabContent.vue';

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    FirstTabContent,
    SecondTabContent,
    ThirdTabContent
  },
  created() {
    this.fillFirstTab();
  },
  data() {
    return {
      arrCtmts: [],

      //First Tab
      loadingFirstTab: false,
      bdgdVersionId: null,
      itemsTableFirstTab: [],
      titleItem: null,
      selectedItemsFirstTab: [],

      //Second Tab
      ctmts: [],
      mapType: 'ilha',
      numberOfIslands: 10,
      selectedCtmt: null,
      entities: [],
      selectedEntities: []
    };
  },
  computed: {
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },
  methods: {
    async fillFirstTab() {
      this.loadingFirstTab = true;
      await ExportacoesBdgdsService.getDatesAndRegistersImportedEntities()
        .then(({ data }) => {
          this.itemsTableFirstTab = data;
          let selectedBdgdVersion = this.itemsTableFirstTab.filter(
            (item) =>
              item.bdgd_versao_id === this.userSelectedCompanyBdgdVersion.id
          );
          this.selectedItemsFirstTab = selectedBdgdVersion;
        })
        .finally(() => {
          this.loadingFirstTab = false;
        });
    },
    fillSecondTab() {
      if (!this.bdgdVersionId) return;
      this.getCtmtsByBdgdVersaoId();
      this.getEntities();
    },
    getCtmtsByBdgdVersaoId() {
      MapasSequenciamentoEletricoService.getCtmtsByBdgdVersaoId(
        this.bdgdVersionId
      )
        .then(({ data }) => (this.ctmts = data))
        .catch(() =>
          this.$emit('stepReleased', { numberStep: 1, available: false })
        );
    },
    getEntities() {
      MapasSequenciamentoEletricoService.getEntities(this.bdgdVersionId)
        .then(({ data }) => {
          this.entities = data;
          this.selectedEntities = data;
        })
        .catch(() => {
          const entities = [
            { element: 'CHVMT', bdgd_entity: 'UNSEMT' },
            { element: 'REGUL', bdgd_entity: 'UNREMT' },
            { element: 'RML', bdgd_entity: 'RAMLIG' },
            { element: 'SEGMBT', bdgd_entity: 'SSDBT' },
            { element: 'SEGMMT', bdgd_entity: 'SSDMT' },
            { element: 'TRAFO', bdgd_entity: 'UNTRMT' },
            { element: 'CTMT', bdgd_entity: 'CTMT' },
            { element: 'PIP', bdgd_entity: 'PIP' },
            { element: 'UCMT', bdgd_entity: 'UCMT' },
            { element: 'UCBT', bdgd_entity: 'UCBT' }
          ];
          this.entities = entities;
          this.selectedEntities = entities;
        });
    },
    exportData() {
      const parametros = {
        ctmt: this.selectedCtmt[0].cod_id,
        tipo: this.mapType,
        numero_ilhas: this.numberOfIslands,
        lista_elementos: this.selectedEntities.map((entity) => entity.element),
        caminho: ''
      };
      return {
        id: null,
        nome: this.titleItem,
        parametros: JSON.stringify(parametros),
        bdgd_versao_id: this.selectedItemsFirstTab[0].bdgd_versao_id
      };
    }
  },
  watch: {
    selectedItemsFirstTab(arrNewVal) {
      this.bdgdVersionId = arrNewVal.length
        ? arrNewVal[0].bdgd_versao_id
        : null;
      this.fillSecondTab();
    }
  }
};
</script>
